




































































import { Vue, Component, Prop } from "vue-property-decorator";
import firebase from "@/plugins/firebase";
import Confirm from "@/components/Confirm.vue";

interface User {
  id: string;
  displayName: string;
  email: string;
}

interface Course {
  id: string;
  name: string;
}

interface CourseRegistration {
  id: string;
  courseId: string;
  courseName: string;
  startDate: Date;
  finishDate: Date;
}

@Component<AddRemoveCourse>({
  components: {
    Confirm
  },

  watch: {
    async user() {
      await this.getAllCourses();
      await this.getCourseRegs();
    }
  }
})
export default class AddRemoveCourse extends Vue {
  @Prop() user!: User;

  disabled = false;

  courses = [] as Course[];

  selectedCourses = [] as string[];

  courseRegs = [] as CourseRegistration[];

  close() {
    this.selectedCourses = [];
    this.$emit("close");
  }

  async getAllCourses() {
    this.courses = [];

    try {
      const coursesQs = await firebase
        .firestore()
        .collection("/apps/kahev-akademi/courses")
        .orderBy("name")
        .get();

      coursesQs.forEach(course => {
        this.courses.push({
          id: course.id,
          name: course.data().name
        });
      });
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Dersler alınamadı."
      });

      throw new Error(`Dersler alınamadı. ${err.message}`);
    }
  }

  async getCourseRegs() {
    this.courseRegs = [];

    try {
      const cregsQs = await firebase
        .firestore()
        .collection("/apps/kahev-akademi/courseRegs")
        .where("uid", "==", this.user.id)
        .orderBy("startDate", "desc")
        .get();

      cregsQs.forEach(creg => {
        const course = this.courses.find(c => c.id == creg.data().courseId);

        this.courseRegs.push({
          id: creg.id,
          courseId: course ? course.id : "",
          courseName: course ? course.name : "",
          startDate: creg.data().startDate.toDate(),
          finishDate: creg.data().finishDate.toDate()
        });
      });
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Kayıtlı dersler alınamadı."
      });

      throw new Error(`Kayıtlı dersler alınamadı. ${err.message}`);
    }
  }

  async addCourseRegs() {
    const $confirm = this.$refs.confirm as InstanceType<typeof Confirm>;
    const confirmation = await $confirm.open(
      "Emin misiniz?",
      "Seçili dersler eklemek istediğinizden emin misiniz?",
      {
        color: "red",
        width: 360
      }
    );

    if (!confirmation) return;

    try {
      // Ders kayıtlarını oluştur
      this.selectedCourses.map(async (courseId: string) => {
        // Veritabanına ekle
        await this.$store.dispatch("lms/addCourseReg", {
          uid: this.user.id,
          courseId: courseId
        });
      });

      setTimeout(async () => {
        await this.getCourseRegs();
        this.selectedCourses = [];
      }, 1000);
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Dersler kayıtları oluşturulamadı."
      });

      throw new Error(`Ders kayıtları oluşturulamadı. ${err.message}`);
    }
  }

  async removeCourseReg(cregId: string) {
    const $confirm = this.$refs.confirm as InstanceType<typeof Confirm>;
    const confirmation = await $confirm.open(
      "Emin misiniz?",
      `Eğitimi silmek istediğinizden emin misiniz?`,
      {
        color: "red",
        width: 360
      }
    );

    if (!confirmation) return;

    try {
      // Ders kaydını veritabanından sil
      await this.$store.dispatch("lms/removeCourseReg", cregId);

      // Ders kaydını hafızadan sil
      const i = this.courseRegs.findIndex(c => c.id == cregId);
      this.courseRegs.splice(i, 1);
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Ders kaydı silinemedi."
      });

      throw new Error(`Ders kaydı silinemedi. ${err.message}`);
    }
  }

  async mounted() {
    await this.getAllCourses();
    await this.getCourseRegs();
  }
}
