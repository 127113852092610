<template>
  <div id="editUser">
    <v-card>
      <v-form ref="form" @submit.prevent="update()">
        <v-card-title>Düzenle</v-card-title>
        <v-divider />
        <v-card-text>
          <div class="d-flex justify-space-around">
            <v-text-field
              id="firstName"
              v-model="user.firstName"
              label="Ad"
              name="firstName"
              prepend-icon="person"
              type="text"
              required
              :rules="firstNameRules"
            />
            <v-text-field
              id="lastName"
              v-model="user.lastName"
              label="Soyad"
              name="lastName"
              type="text"
              required
              :rules="lastNameRules"
              class="ml-4"
            />
          </div>
          <v-text-field
            id="email"
            v-model="user.email"
            label="e-Posta"
            name="email"
            prepend-icon="alternate_email"
            type="text"
            required
            :rules="emailRules"
          />
          <v-select
            :items="roles"
            v-model="user.roles"
            item-text="title"
            item-value="id"
            label="Roller"
            multiple
            prepend-icon="assignment_ind"
            :rules="roleRules"
            required
          ></v-select>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">İptal Et</v-btn>
          <v-btn color="primary" type="submit" text :disabled="submitDisabled"
            >Güncelle</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    roles: [
      { id: "admin", title: "Sistem Yöneticisi" },
      { id: "student", title: "Öğrenci" },
      { id: "instructor", title: "Eğitmen" }
    ],
    submitDisabled: false
  }),
  computed: {
    firstNameRules() {
      return [v => !!v || "Lütfen adınızı yazınız"];
    },
    lastNameRules() {
      return [v => !!v || "Lütfen soyadınızı yazınız"];
    },
    emailRules() {
      return [
        v => !!v || "Lütfen e-postanızı yazınız",
        v => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
      ];
    },
    roleRules() {
      return [v => v.length > 0 || "Lütfen rol belirtiniz."];
    }
  },
  methods: {
    close() {
      this.reset();
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      //this.$refs.form.reset();

      this.user.firstName = "";
      this.user.lastName = "";
      this.user.email = "";
      this.user.roles = [];

      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async update() {
      if (this.validate()) {
        this.submitDisabled = true;
        this.$notify("Kullanıcı güncelleniyor. Lütfen bekleyin...");

        try {
          const newUser = {
            uid: this.user.uid,
            email: this.user.email,
            emailVerified: this.user.emailVerified,
            disabled: this.user.disabled,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            roles: this.user.roles
          };

          const updateUserFunc = firebase
            .functions()
            .httpsCallable("akademi-auth-updateUser");

          await updateUserFunc(newUser);

          this.submitDisabled = false;
          this.refresh();
          this.$notify({
            type: "success",
            text: "Kullanıcı başarıyla güncellendi."
          });
          this.close();
        } catch (err) {
          this.submitDisabled = false;

          this.$notify({
            type: "error",
            title: "Hata!",
            text: "Kullanıcı güncellenemedi."
          });
          console.log(`HATA: Kullanıcı güncellenemedi. ${err}`);
        }
      }
    }
  }
};
</script>
